<template>

  <div class="market card-tm coin-list">
    <div class="d-flex flex-column justify-content-center">
      <div class="col-12 p-lg-0">
        <div class="card">
          <div class="card-body gradiant-bg">
            <div class="d-flex justify-content-center align-items-center flex-column gap-3 flex-lg-row">
              <div class="col-12 col-lg-4">
                <h4 class="fw-600 text-white">Take control of your <span class="text-primary">Wealth</span> more</h4>
                  <p class="font-light-gray2 fs-17 ">Stay informed with <span class="text-primary">realtime</span> market data on
                  top cryptocurrecies bt market capitalizations Easily track performance and trends of most valuable
                  asset
                  in market.</p>
              </div>
              <div class="col-12 col-lg-5 offset-lg-1 text-center">
                <img :src="require(`../../public/assets/images/layout/Frame.png`)" class="img-fluid w-50" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center">
              <div class="col-11 px-3">
                <h5 class="fs-24">Market Cap</h5>
                  <div class="flat-tabs">
                    <div class="d-flex flex-column flex-lg-row">
                      <div class="col-12 col-lg-9 ">
                        <ul class="nav nav-pills my-3" id="pills-tab" role="tablist">
                          <li class="nav-item fs-14" role="presentation" v-for="(item, index) in tabItems" :key="index">
                            <button class="nav-link nav_btns fs-14 fw-400"
                              :class="active_tab == item.id ? 'active' : ''" @click="selectTab(item.id)"
                              :id="`pills-${item.id}-tab`" data-bs-toggle="pill" :data-bs-target="`#pills-${item.id}`"
                              type="button" role="tab" :aria-controls="`pills-${item.id}`" aria-selected="true">{{
                                item.tab
                              }}</button>
                          </li>
                        </ul>
                      </div>
                      <!-- <div class="col-lg col-12">
                      <div class="input-group search-input">
                        <span class="input-group-text"><i class="fa-solid fa-magnifying-glass"></i></span>
                        <input type="search" v-model="search" ref="searchInput" v-on:keyup="searchData()"
                          placeholder="Search" class=" form-control">
                      </div>
                    </div> -->
                    </div>

                    <!--ul class="menu-tab1 nav  nav-pills" id="myTab" role="tablist">

                      <li class="" role="presentation" v-for="(tabs_pair, index) in tabs_pair_data"
                        :key="index">
                        <button class="nav-link fs-14" id="usdt-tab" :class="tab == tabs_pair ? 'active' : ''"
                          @click="pairChange(tabs_pair)" data-bs-toggle="tab" data-bs-target="#usdt" type="button"
                          role="tab" aria-controls="usdt" aria-selected="true">
                          {{ tabs_pair }}
                        </button>
                      </li>
                    </ul-->
                    <div class="col-xl-12 col-lg-12 col-md-12 content-tab">
                      <div class="table-box content-inner">
                        <div id="myTabContent" class="tab-content">
                          <div id="usdt" role="tabpanel" aria-labelledby="usdt-tab" class="tab-pane show active">
                            <div class="table-container p-2">
                              <div class="table-responsive">
                                <table id="table-list" class="table table-borderless align-middle">
                                  <div id="exchnage-sidebar-loader" v-if="pair_table_data.length == 0"
                                    class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                                  <thead class="">
                                    <tr class="fs-13 fw-500 font-secondary m-0 text-nowrap">
                                      <th scope="col" v-for="market_table in market_table_data" :key="market_table">{{
                                        market_table
                                      }}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-if="pair_table_data.length < 0">
                                      <tr class="align-middle" v-for="i in market_table_data.length" :key="i">
                                        <td class="text-uppercase" v-for="j in market_table_data.length" :key="j">
                                          <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave"
                                            color="#adb5bd" wave-color="#fff" :rounded="true" />
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="pair_table_data.length > 0 && active_tab == 'all'">
                                      <tr v-for="(tabledata, index) in pair_table_data" :key="index">

                                        <td>
                                          <a href="#"> 
                                            <!-- <img :src="tabledata.image" alt="" width="25"
                                              class="mr-3 rounded-circle" /> -->
                                            <span class="unit"> {{ tabledata.currency }}</span></a>
                                        </td>
                                        <td :style="{ color: tabledata.flag == 2 ? 'var(--red)' : 'var(--green)' }">${{
                                          parseFloat(tabledata.price).toFixed(2)
                                        }}</td>

                                        <td :style="{ color: tabledata.flag == 2 ? 'var(--red)' : 'var(--green)' }">${{
                                          parseFloat(tabledata.high).toFixed(2)
                                        }}</td>

                                        <td :style="{ color: tabledata.flag == 2 ? 'var(--red)' : 'var(--green)' }">${{
                                          parseFloat(tabledata.low).toFixed(2)
                                        }}
                                        </td>
                                        <td class="up" :style="{
                                          color:
                                            tabledata.change > 0
                                              ? 'var(--green)'
                                              : 'var(--red)',
                                        }">
                                          {{ tabledata.change }}%</td>

                                        <td class="">
                                          <svg id="SvgjsSvg15261" width="100" height="40"
                                            xmlns="http://www.w3.org/2000/svg" version="1.1"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev"
                                            class="apexcharts-svg" xmlns:data="ApexChartsNS" transform="translate(0, 0)"
                                            style="background: transparent;">
                                            <g id="SvgjsG15263" class="apexcharts-inner apexcharts-graphical"
                                              transform="translate(0, 0)">
                                              <defs id="SvgjsDefs15262">
                                                <clipPath id="gridRectMaskiqv1uf51">
                                                  <rect id="SvgjsRect15269" width="106" height="42" x="-3" y="-1" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none"
                                                    stroke-dasharray="0" fill="#fff">
                                                  </rect>
                                                </clipPath>
                                                <clipPath id="forecastMaskiqv1uf51"></clipPath>
                                                <clipPath id="nonForecastMaskiqv1uf51"></clipPath>
                                                <clipPath id="gridRectMarkerMaskiqv1uf51">
                                                  <rect id="SvgjsRect15270" width="104" height="44" x="-2" y="-2" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none"
                                                    stroke-dasharray="0" fill="#fff">
                                                  </rect>
                                                </clipPath>
                                                <linearGradient id="SvgjsLinearGradient15275" x1="0" y1="0" x2="0"
                                                  y2="1">
                                                  <stop id="SvgjsStop15276" stop-opacity="0.65"
                                                    stop-color="rgba(211,53,53,0.65)" offset="0"></stop>
                                                  <stop id="SvgjsStop15277" stop-opacity="0.5"
                                                    stop-color="rgba(233,154,154,0.5)" offset="1"></stop>
                                                  <stop id="SvgjsStop15278" stop-opacity="0.5"
                                                    stop-color="rgba(233,154,154,0.5)" offset="1"></stop>
                                                </linearGradient>
                                              </defs>
                                              <line id="SvgjsLine15268" x1="59.5" y1="0" x2="59.5" y2="40"
                                                stroke="#b6b6b6" stroke-dasharray="3" stroke-linecap="butt"
                                                class="apexcharts-xcrosshairs" x="59.5" y="0" width="1" height="40"
                                                fill="#b1b9c4" filter="none" fill-opacity="0.9" stroke-width="1"></line>
                                              <g id="SvgjsG15281" class="apexcharts-xaxis" transform="translate(0, 0)">
                                                <g id="SvgjsG15282" class="apexcharts-xaxis-texts-g"
                                                  transform="translate(0, 4)">
                                                </g>
                                              </g>
                                              <g id="SvgjsG15300" class="apexcharts-grid">
                                                <g id="SvgjsG15301" class="apexcharts-gridlines-horizontal"
                                                  style="display: none;">
                                                  <line id="SvgjsLine15303" x1="0" y1="0" x2="100" y2="0"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15304" x1="0" y1="10" x2="100" y2="10"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15305" x1="0" y1="20" x2="100" y2="20"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15306" x1="0" y1="30" x2="100" y2="30"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15307" x1="0" y1="40" x2="100" y2="40"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                </g>
                                                <g id="SvgjsG15302" class="apexcharts-gridlines-vertical"
                                                  style="display: none;">
                                                </g>
                                                <line id="SvgjsLine15309" x1="0" y1="40" x2="100" y2="40"
                                                  stroke="transparent" stroke-dasharray="0" stroke-linecap="butt">
                                                </line>
                                                <line id="SvgjsLine15308" x1="0" y1="1" x2="0" y2="40"
                                                  stroke="transparent" stroke-dasharray="0" stroke-linecap="butt">
                                                </line>
                                              </g>
                                              <g id="SvgjsG15271" class="apexcharts-area-series apexcharts-plot-series">
                                                <g id="SvgjsG15272" class="apexcharts-series" seriesName="seriesx1"
                                                  data:longestSeries="true" rel="1" data:realIndex="0">
                                                  <path id="SvgjsPath15279"
                                                    d="M 0 40L 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5C 100 38.5 100 38.5 100 40M 100 38.5z"
                                                    fill="url(#SvgjsLinearGradient15275)" fill-opacity="1"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="0"
                                                    stroke-dasharray="0" class="apexcharts-area" index="0"
                                                    clip-path="url(#gridRectMaskiqv1uf51)"
                                                    pathTo="M 0 40L 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5C 100 38.5 100 38.5 100 40M 100 38.5z"
                                                    pathFrom="M -1 40L -1 40L 6.666666666666667 40L 13.333333333333334 40L 20 40L 26.666666666666668 40L 33.333333333333336 40L 40 40L 46.66666666666667 40L 53.333333333333336 40L 60 40L 66.66666666666667 40L 73.33333333333334 40L 80 40L 86.66666666666667 40L 93.33333333333334 40L 100 40">
                                                  </path>
                                                  <path id="SvgjsPath15280"
                                                    d="M 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5"
                                                    fill="none" fill-opacity="1" stroke="#d33535" stroke-opacity="1"
                                                    stroke-linecap="butt" stroke-width="2" stroke-dasharray="0"
                                                    class="apexcharts-area" index="0"
                                                    clip-path="url(#gridRectMaskiqv1uf51)"
                                                    pathTo="M 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5"
                                                    pathFrom="M -1 40L -1 40L 6.666666666666667 40L 13.333333333333334 40L 20 40L 26.666666666666668 40L 33.333333333333336 40L 40 40L 46.66666666666667 40L 53.333333333333336 40L 60 40L 66.66666666666667 40L 73.33333333333334 40L 80 40L 86.66666666666667 40L 93.33333333333334 40L 100 40">
                                                  </path>
                                                  <g id="SvgjsG15273" class="apexcharts-series-markers-wrap"
                                                    data:realIndex="0">
                                                    <g class="apexcharts-series-markers">
                                                      <circle id="SvgjsCircle15315" r="0" cx="60" cy="20"
                                                        class="apexcharts-marker wvzb9e4hg no-pointer-events"
                                                        stroke="#ffffff" fill="#d33535" fill-opacity="1"
                                                        stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                      </circle>
                                                    </g>
                                                  </g>
                                                </g>
                                                <g id="SvgjsG15274" class="apexcharts-datalabels" data:realIndex="0">
                                                </g>
                                              </g>
                                              <line id="SvgjsLine15310" x1="0" y1="0" x2="100" y2="0" stroke="#b6b6b6"
                                                stroke-dasharray="0" stroke-width="1" stroke-linecap="butt"
                                                class="apexcharts-ycrosshairs"></line>
                                              <line id="SvgjsLine15311" x1="0" y1="0" x2="100" y2="0"
                                                stroke-dasharray="0" stroke-width="0" stroke-linecap="butt"
                                                class="apexcharts-ycrosshairs-hidden">
                                              </line>
                                              <g id="SvgjsG15312" class="apexcharts-yaxis-annotations"></g>
                                              <g id="SvgjsG15313" class="apexcharts-xaxis-annotations"></g>
                                              <g id="SvgjsG15314" class="apexcharts-point-annotations"></g>
                                            </g>
                                            <rect id="SvgjsRect15267" width="0" height="0" x="0" y="0" rx="0" ry="0"
                                              opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                              fill="#fefefe">
                                            </rect>
                                            <g id="SvgjsG15299" class="apexcharts-yaxis" rel="0"
                                              transform="translate(-18, 0)">
                                            </g>
                                            <g id="SvgjsG15264" class="apexcharts-annotations"></g>
                                          </svg>
                                        </td>
                                        <td>
                                          <div><router-link :to="'/spot/' + tabledata.symbol"
                                              class="btn text-primary fw-600 fs-14"> Trade </router-link>
                                          </div>
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="topGainerData.length > 0 && active_tab == 'gainer'">
                                      <tr v-for="(tabledata, index) in topGainerData" :key="index">

                                        <td>
                                          <a href="#">
                                            <!-- <img :src="tabledata.image" alt="" width="25"
                                            class="mr-3 rounded-circle" /> -->
                                            <span class="unit"> {{ tabledata.symbol }}</span></a>
                                        </td>
                                        <td :style="{ color: tabledata.lastPrice < 0 ? 'var(--red)' : 'var(--green)' }">
                                          ${{
                                            parseFloat(tabledata.lastPrice).toFixed(2)
                                          }}</td>

                                        <td :style="{ color: tabledata.highPrice < 0 ? 'var(--red)' : 'var(--green)' }">
                                          ${{
                                            parseFloat(tabledata.highPrice).toFixed(2)
                                          }}</td>

                                        <td :style="{ color: tabledata.lastPrice < 0 ? 'var(--red)' : 'var(--green)' }">
                                          ${{
                                            parseFloat(tabledata.lastPrice).toFixed(2)
                                          }}
                                        </td>
                                        <td class="up" :style="{
                                          color:
                                            tabledata.priceChangePercent > 0
                                              ? 'var(--green)'
                                              : 'var(--red)',
                                        }">
                                          {{ tabledata.priceChangePercent }}%</td>

                                        <td class="">
                                          <svg id="SvgjsSvg15261" width="100" height="40"
                                            xmlns="http://www.w3.org/2000/svg" version="1.1"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev"
                                            class="apexcharts-svg" xmlns:data="ApexChartsNS" transform="translate(0, 0)"
                                            style="background: transparent;">
                                            <g id="SvgjsG15263" class="apexcharts-inner apexcharts-graphical"
                                              transform="translate(0, 0)">
                                              <defs id="SvgjsDefs15262">
                                                <clipPath id="gridRectMaskiqv1uf51">
                                                  <rect id="SvgjsRect15269" width="106" height="42" x="-3" y="-1" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none"
                                                    stroke-dasharray="0" fill="#fff">
                                                  </rect>
                                                </clipPath>
                                                <clipPath id="forecastMaskiqv1uf51"></clipPath>
                                                <clipPath id="nonForecastMaskiqv1uf51"></clipPath>
                                                <clipPath id="gridRectMarkerMaskiqv1uf51">
                                                  <rect id="SvgjsRect15270" width="104" height="44" x="-2" y="-2" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none"
                                                    stroke-dasharray="0" fill="#fff">
                                                  </rect>
                                                </clipPath>
                                                <linearGradient id="SvgjsLinearGradient15275" x1="0" y1="0" x2="0"
                                                  y2="1">
                                                  <stop id="SvgjsStop15276" stop-opacity="0.65"
                                                    stop-color="rgba(211,53,53,0.65)" offset="0"></stop>
                                                  <stop id="SvgjsStop15277" stop-opacity="0.5"
                                                    stop-color="rgba(233,154,154,0.5)" offset="1"></stop>
                                                  <stop id="SvgjsStop15278" stop-opacity="0.5"
                                                    stop-color="rgba(233,154,154,0.5)" offset="1"></stop>
                                                </linearGradient>
                                              </defs>
                                              <line id="SvgjsLine15268" x1="59.5" y1="0" x2="59.5" y2="40"
                                                stroke="#b6b6b6" stroke-dasharray="3" stroke-linecap="butt"
                                                class="apexcharts-xcrosshairs" x="59.5" y="0" width="1" height="40"
                                                fill="#b1b9c4" filter="none" fill-opacity="0.9" stroke-width="1"></line>
                                              <g id="SvgjsG15281" class="apexcharts-xaxis" transform="translate(0, 0)">
                                                <g id="SvgjsG15282" class="apexcharts-xaxis-texts-g"
                                                  transform="translate(0, 4)">
                                                </g>
                                              </g>
                                              <g id="SvgjsG15300" class="apexcharts-grid">
                                                <g id="SvgjsG15301" class="apexcharts-gridlines-horizontal"
                                                  style="display: none;">
                                                  <line id="SvgjsLine15303" x1="0" y1="0" x2="100" y2="0"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15304" x1="0" y1="10" x2="100" y2="10"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15305" x1="0" y1="20" x2="100" y2="20"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15306" x1="0" y1="30" x2="100" y2="30"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15307" x1="0" y1="40" x2="100" y2="40"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                </g>
                                                <g id="SvgjsG15302" class="apexcharts-gridlines-vertical"
                                                  style="display: none;">
                                                </g>
                                                <line id="SvgjsLine15309" x1="0" y1="40" x2="100" y2="40"
                                                  stroke="transparent" stroke-dasharray="0" stroke-linecap="butt">
                                                </line>
                                                <line id="SvgjsLine15308" x1="0" y1="1" x2="0" y2="40"
                                                  stroke="transparent" stroke-dasharray="0" stroke-linecap="butt">
                                                </line>
                                              </g>
                                              <g id="SvgjsG15271" class="apexcharts-area-series apexcharts-plot-series">
                                                <g id="SvgjsG15272" class="apexcharts-series" seriesName="seriesx1"
                                                  data:longestSeries="true" rel="1" data:realIndex="0">
                                                  <path id="SvgjsPath15279"
                                                    d="M 0 40L 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5C 100 38.5 100 38.5 100 40M 100 38.5z"
                                                    fill="url(#SvgjsLinearGradient15275)" fill-opacity="1"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="0"
                                                    stroke-dasharray="0" class="apexcharts-area" index="0"
                                                    clip-path="url(#gridRectMaskiqv1uf51)"
                                                    pathTo="M 0 40L 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5C 100 38.5 100 38.5 100 40M 100 38.5z"
                                                    pathFrom="M -1 40L -1 40L 6.666666666666667 40L 13.333333333333334 40L 20 40L 26.666666666666668 40L 33.333333333333336 40L 40 40L 46.66666666666667 40L 53.333333333333336 40L 60 40L 66.66666666666667 40L 73.33333333333334 40L 80 40L 86.66666666666667 40L 93.33333333333334 40L 100 40">
                                                  </path>
                                                  <path id="SvgjsPath15280"
                                                    d="M 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5"
                                                    fill="none" fill-opacity="1" stroke="#d33535" stroke-opacity="1"
                                                    stroke-linecap="butt" stroke-width="2" stroke-dasharray="0"
                                                    class="apexcharts-area" index="0"
                                                    clip-path="url(#gridRectMaskiqv1uf51)"
                                                    pathTo="M 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5"
                                                    pathFrom="M -1 40L -1 40L 6.666666666666667 40L 13.333333333333334 40L 20 40L 26.666666666666668 40L 33.333333333333336 40L 40 40L 46.66666666666667 40L 53.333333333333336 40L 60 40L 66.66666666666667 40L 73.33333333333334 40L 80 40L 86.66666666666667 40L 93.33333333333334 40L 100 40">
                                                  </path>
                                                  <g id="SvgjsG15273" class="apexcharts-series-markers-wrap"
                                                    data:realIndex="0">
                                                    <g class="apexcharts-series-markers">
                                                      <circle id="SvgjsCircle15315" r="0" cx="60" cy="20"
                                                        class="apexcharts-marker wvzb9e4hg no-pointer-events"
                                                        stroke="#ffffff" fill="#d33535" fill-opacity="1"
                                                        stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                      </circle>
                                                    </g>
                                                  </g>
                                                </g>
                                                <g id="SvgjsG15274" class="apexcharts-datalabels" data:realIndex="0">
                                                </g>
                                              </g>
                                              <line id="SvgjsLine15310" x1="0" y1="0" x2="100" y2="0" stroke="#b6b6b6"
                                                stroke-dasharray="0" stroke-width="1" stroke-linecap="butt"
                                                class="apexcharts-ycrosshairs"></line>
                                              <line id="SvgjsLine15311" x1="0" y1="0" x2="100" y2="0"
                                                stroke-dasharray="0" stroke-width="0" stroke-linecap="butt"
                                                class="apexcharts-ycrosshairs-hidden">
                                              </line>
                                              <g id="SvgjsG15312" class="apexcharts-yaxis-annotations"></g>
                                              <g id="SvgjsG15313" class="apexcharts-xaxis-annotations"></g>
                                              <g id="SvgjsG15314" class="apexcharts-point-annotations"></g>
                                            </g>
                                            <rect id="SvgjsRect15267" width="0" height="0" x="0" y="0" rx="0" ry="0"
                                              opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                              fill="#fefefe">
                                            </rect>
                                            <g id="SvgjsG15299" class="apexcharts-yaxis" rel="0"
                                              transform="translate(-18, 0)">
                                            </g>
                                            <g id="SvgjsG15264" class="apexcharts-annotations"></g>
                                          </svg>
                                        </td>
                                        <td>
                                          <div><router-link :to="'/spot/' + tabledata.symbol"
                                              class="btn text-primary fw-600 fs-14"> Trade </router-link>
                                          </div>
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="topLosserData.length > 0 && active_tab == 'loser'">
                                      <tr v-for="(tabledata, index) in topLosserData" :key="index">

                                        <td>
                                          <a href="#">
                                            <!-- <img :src="tabledata.image" alt="" width="25"
                                            class="mr-3 rounded-circle" /> -->
                                            <span class="unit"> {{ tabledata.symbol }}</span></a>
                                        </td>
                                        <td :style="{ color: tabledata.lastPrice < 0 ? 'var(--red)' : 'var(--green)' }">
                                          ${{
                                            parseFloat(tabledata.lastPrice).toFixed(2)
                                          }}</td>

                                        <td :style="{ color: tabledata.highPrice < 0 ? 'var(--red)' : 'var(--green)' }">
                                          ${{
                                            parseFloat(tabledata.highPrice).toFixed(2)
                                          }}</td>

                                        <td :style="{ color: tabledata.lowPrice < 0 ? 'var(--red)' : 'var(--green)' }">
                                          ${{
                                            parseFloat(tabledata.lowPrice).toFixed(2)
                                          }}
                                        </td>
                                        <td class="up" :style="{
                                          color:
                                            tabledata.priceChangePercent > 0
                                              ? 'var(--green)'
                                              : 'var(--red)',
                                        }">
                                          {{ tabledata.priceChangePercent }}%</td>

                                        <td class="">
                                          <svg id="SvgjsSvg15261" width="100" height="40"
                                            xmlns="http://www.w3.org/2000/svg" version="1.1"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev"
                                            class="apexcharts-svg" xmlns:data="ApexChartsNS" transform="translate(0, 0)"
                                            style="background: transparent;">
                                            <g id="SvgjsG15263" class="apexcharts-inner apexcharts-graphical"
                                              transform="translate(0, 0)">
                                              <defs id="SvgjsDefs15262">
                                                <clipPath id="gridRectMaskiqv1uf51">
                                                  <rect id="SvgjsRect15269" width="106" height="42" x="-3" y="-1" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none"
                                                    stroke-dasharray="0" fill="#fff">
                                                  </rect>
                                                </clipPath>
                                                <clipPath id="forecastMaskiqv1uf51"></clipPath>
                                                <clipPath id="nonForecastMaskiqv1uf51"></clipPath>
                                                <clipPath id="gridRectMarkerMaskiqv1uf51">
                                                  <rect id="SvgjsRect15270" width="104" height="44" x="-2" y="-2" rx="0"
                                                    ry="0" opacity="1" stroke-width="0" stroke="none"
                                                    stroke-dasharray="0" fill="#fff">
                                                  </rect>
                                                </clipPath>
                                                <linearGradient id="SvgjsLinearGradient15275" x1="0" y1="0" x2="0"
                                                  y2="1">
                                                  <stop id="SvgjsStop15276" stop-opacity="0.65"
                                                    stop-color="rgba(211,53,53,0.65)" offset="0"></stop>
                                                  <stop id="SvgjsStop15277" stop-opacity="0.5"
                                                    stop-color="rgba(233,154,154,0.5)" offset="1"></stop>
                                                  <stop id="SvgjsStop15278" stop-opacity="0.5"
                                                    stop-color="rgba(233,154,154,0.5)" offset="1"></stop>
                                                </linearGradient>
                                              </defs>
                                              <line id="SvgjsLine15268" x1="59.5" y1="0" x2="59.5" y2="40"
                                                stroke="#b6b6b6" stroke-dasharray="3" stroke-linecap="butt"
                                                class="apexcharts-xcrosshairs" x="59.5" y="0" width="1" height="40"
                                                fill="#b1b9c4" filter="none" fill-opacity="0.9" stroke-width="1"></line>
                                              <g id="SvgjsG15281" class="apexcharts-xaxis" transform="translate(0, 0)">
                                                <g id="SvgjsG15282" class="apexcharts-xaxis-texts-g"
                                                  transform="translate(0, 4)">
                                                </g>
                                              </g>
                                              <g id="SvgjsG15300" class="apexcharts-grid">
                                                <g id="SvgjsG15301" class="apexcharts-gridlines-horizontal"
                                                  style="display: none;">
                                                  <line id="SvgjsLine15303" x1="0" y1="0" x2="100" y2="0"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15304" x1="0" y1="10" x2="100" y2="10"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15305" x1="0" y1="20" x2="100" y2="20"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15306" x1="0" y1="30" x2="100" y2="30"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                  <line id="SvgjsLine15307" x1="0" y1="40" x2="100" y2="40"
                                                    stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                                    class="apexcharts-gridline">
                                                  </line>
                                                </g>
                                                <g id="SvgjsG15302" class="apexcharts-gridlines-vertical"
                                                  style="display: none;">
                                                </g>
                                                <line id="SvgjsLine15309" x1="0" y1="40" x2="100" y2="40"
                                                  stroke="transparent" stroke-dasharray="0" stroke-linecap="butt">
                                                </line>
                                                <line id="SvgjsLine15308" x1="0" y1="1" x2="0" y2="40"
                                                  stroke="transparent" stroke-dasharray="0" stroke-linecap="butt">
                                                </line>
                                              </g>
                                              <g id="SvgjsG15271" class="apexcharts-area-series apexcharts-plot-series">
                                                <g id="SvgjsG15272" class="apexcharts-series" seriesName="seriesx1"
                                                  data:longestSeries="true" rel="1" data:realIndex="0">
                                                  <path id="SvgjsPath15279"
                                                    d="M 0 40L 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5C 100 38.5 100 38.5 100 40M 100 38.5z"
                                                    fill="url(#SvgjsLinearGradient15275)" fill-opacity="1"
                                                    stroke-opacity="1" stroke-linecap="butt" stroke-width="0"
                                                    stroke-dasharray="0" class="apexcharts-area" index="0"
                                                    clip-path="url(#gridRectMaskiqv1uf51)"
                                                    pathTo="M 0 40L 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5C 100 38.5 100 38.5 100 40M 100 38.5z"
                                                    pathFrom="M -1 40L -1 40L 6.666666666666667 40L 13.333333333333334 40L 20 40L 26.666666666666668 40L 33.333333333333336 40L 40 40L 46.66666666666667 40L 53.333333333333336 40L 60 40L 66.66666666666667 40L 73.33333333333334 40L 80 40L 86.66666666666667 40L 93.33333333333334 40L 100 40">
                                                  </path>
                                                  <path id="SvgjsPath15280"
                                                    d="M 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5"
                                                    fill="none" fill-opacity="1" stroke="#d33535" stroke-opacity="1"
                                                    stroke-linecap="butt" stroke-width="2" stroke-dasharray="0"
                                                    class="apexcharts-area" index="0"
                                                    clip-path="url(#gridRectMaskiqv1uf51)"
                                                    pathTo="M 0 12.5C 2.3333333333333335 12.5 4.333333333333334 12 6.666666666666667 12C 9 12 11 14.5 13.333333333333334 14.5C 15.666666666666668 14.5 17.666666666666668 15.5 20 15.5C 22.333333333333332 15.5 24.333333333333336 8.5 26.666666666666668 8.5C 29 8.5 31.000000000000004 22.5 33.333333333333336 22.5C 35.66666666666667 22.5 37.66666666666667 18 40 18C 42.333333333333336 18 44.333333333333336 20 46.66666666666667 20C 49 20 51 22 53.333333333333336 22C 55.66666666666667 22 57.66666666666667 20 60 20C 62.333333333333336 20 64.33333333333334 13 66.66666666666667 13C 69 13 71.00000000000001 15.5 73.33333333333334 15.5C 75.66666666666667 15.5 77.66666666666667 8.5 80 8.5C 82.33333333333333 8.5 84.33333333333334 22.5 86.66666666666667 22.5C 89 22.5 91.00000000000001 18 93.33333333333334 18C 95.66666666666667 18 97.66666666666667 38.5 100 38.5"
                                                    pathFrom="M -1 40L -1 40L 6.666666666666667 40L 13.333333333333334 40L 20 40L 26.666666666666668 40L 33.333333333333336 40L 40 40L 46.66666666666667 40L 53.333333333333336 40L 60 40L 66.66666666666667 40L 73.33333333333334 40L 80 40L 86.66666666666667 40L 93.33333333333334 40L 100 40">
                                                  </path>
                                                  <g id="SvgjsG15273" class="apexcharts-series-markers-wrap"
                                                    data:realIndex="0">
                                                    <g class="apexcharts-series-markers">
                                                      <circle id="SvgjsCircle15315" r="0" cx="60" cy="20"
                                                        class="apexcharts-marker wvzb9e4hg no-pointer-events"
                                                        stroke="#ffffff" fill="#d33535" fill-opacity="1"
                                                        stroke-width="2" stroke-opacity="0.9" default-marker-size="0">
                                                      </circle>
                                                    </g>
                                                  </g>
                                                </g>
                                                <g id="SvgjsG15274" class="apexcharts-datalabels" data:realIndex="0">
                                                </g>
                                              </g>
                                              <line id="SvgjsLine15310" x1="0" y1="0" x2="100" y2="0" stroke="#b6b6b6"
                                                stroke-dasharray="0" stroke-width="1" stroke-linecap="butt"
                                                class="apexcharts-ycrosshairs"></line>
                                              <line id="SvgjsLine15311" x1="0" y1="0" x2="100" y2="0"
                                                stroke-dasharray="0" stroke-width="0" stroke-linecap="butt"
                                                class="apexcharts-ycrosshairs-hidden">
                                              </line>
                                              <g id="SvgjsG15312" class="apexcharts-yaxis-annotations"></g>
                                              <g id="SvgjsG15313" class="apexcharts-xaxis-annotations"></g>
                                              <g id="SvgjsG15314" class="apexcharts-point-annotations"></g>
                                            </g>
                                            <rect id="SvgjsRect15267" width="0" height="0" x="0" y="0" rx="0" ry="0"
                                              opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0"
                                              fill="#fefefe">
                                            </rect>
                                            <g id="SvgjsG15299" class="apexcharts-yaxis" rel="0"
                                              transform="translate(-18, 0)">
                                            </g>
                                            <g id="SvgjsG15264" class="apexcharts-annotations"></g>
                                          </svg>
                                        </td>
                                        <td>
                                          <div><router-link :to="'/spot/' + tabledata.symbol"
                                              class="btn text-primary fw-600 fs-14"> Trade </router-link>
                                          </div>
                                        </td>
                                      </tr>
                                    </template>

                                  </tbody>
                                </table>
                                <table class="table text-center table-hover">
                                </table>
                              </div>
                              <!--table-responsive-->
                            </div>
                            <!--pair-table-->
                          </div>
                        </div>
                      </div>
                      <!--table-box-->
                    </div>
                    <!--col-xl-12 col-lg-12 col-md-12-->
                  </div>
                <!--row bottom-row-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--market-main-box-->
  </div>
</template>

<script>

import ApiClass from "@/api/api";
// import fs from 'browserify-fs';
// import JsonFile from '@/jsonFiles/dashboard_market_chart.json';
// import JsonFile from '../jsonFiles/dashboard_market_chart.json';
import VueSkeletonLoader from "skeleton-loader-vue";

export default {
  name: "Market",
  components: {
    VueSkeletonLoader
  },

  data() {
    return {
      active_tab: "all",
      tabItems: [
        { tab: 'All', id: 'all' },
        { tab: 'Top Gainers', id: 'gainer' },
        { tab: 'Top Losers', id: 'loser' },
        // { tab: 'Wallet Balance', id: 'balance'},
      ],
      search: "",
      skeletonLoad: true,
      tableLoad: true,

      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 10,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },

          767: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
        },
      },
      dataBTCUSDT: [],
      dataETHUSDT: [],
      dataTRXUSDT: [],
      dataXRPUSDT: [],
      /****************************************** MARKET DATA LOOP START *******************************************/
      MarketBoxData: [
        {
          market_pair: "Btc-Usdt",
          market_price: "0",
          market_change: "0",
          market_subprice: "0",
          change_percantage_color: "var(--green)",
          seriesSpark3: [
            {
              data: [],
            },
          ],
          chartOptionsSpark3: {
            chart: {
              animations: {
                enabled: false,
              },
              type: "area",
              height: 100,
              sparkline: {
                enabled: true,
              },
            },

            stroke: {
              curve: "smooth",
              width: 1,
              lineCap: 'butt',
            },
            fill: {
              opacity: 0.3,
            },
            xaxis: {
              crosshairs: {
                width: 1,
              },
            },
            yaxis: {
              min: 0,
            },
          },
        },

        {
          market_pair: "ETH-Usdt",
          market_price: "0",
          market_change: "0",
          market_subprice: "0",
          change_percantage_color: "var(--green)",
          seriesSpark3: [
            {
              data: [],
            },
          ],
          chartOptionsSpark3: {
            chart: {
              animations: {
                enabled: false,
              },
              type: "area",
              height: 100,
              sparkline: {
                enabled: true,
              },
            },

            stroke: {
              curve: "smooth",
              width: 1,
              lineCap: 'butt',
            },
            fill: {
              //  colors: ["rgb(255, 0, 0)"],
              //     colors: ["#34c38f"],
              opacity: 0.3,
            },
            xaxis: {
              crosshairs: {
                width: 1,
              },
            },
            yaxis: {
              min: 0,
            },
          },
        },

        {
          market_pair: "TRX-Usdt",
          market_price: "0",
          market_change: "0",
          market_subprice: "0",
          change_percantage_color: "var(--green)",
          seriesSpark3: [
            {
              data: [],
            },
          ],
          chartOptionsSpark3: {
            chart: {
              animations: {
                enabled: false,
              },
              type: "area",
              height: 100,
              sparkline: {
                enabled: true,
              },
            },

            stroke: {
              curve: "smooth",
              width: 1,
              lineCap: 'butt',
            },
            fill: {

              opacity: 0.3,
            },
            xaxis: {
              crosshairs: {
                width: 1,
              },
            },
            yaxis: {
              min: 0,
            },
          },
        },

        {
          market_pair: "XRP-Usdt",
          market_price: "0",
          market_change: "0",
          market_subprice: "0",
          change_percantage_color: "var(--green)",
          seriesSpark3: [
            {
              data: [],
            },
          ],
          chartOptionsSpark3: {
            chart: {
              animations: {
                enabled: false,
              },
              type: "area",
              height: 100,
              sparkline: {
                enabled: true,
              },
            },

            stroke: {
              curve: "smooth",
              width: 1,
              lineCap: 'butt',
            },
            fill: {
              // colors: ["rgb(255, 0, 0)"],
              opacity: 0.3,
            },
            xaxis: {
              crosshairs: {
                width: 1,
              },
            },
            yaxis: {
              min: 0,
            },
          },
        },
      ],

      /****************************************** MARKET DATA LOOP FINISH *******************************************/
      /****************************************** TABS DATA LOOP START *******************************************/
      tab: "USDT",
      tabs_pair_data: ["USDT", "BTC", "TRX", "ETH"],
      market_table_data: ["Coin", "Last Price", "24H High", "24H Low", "24H Change", "Last 7 days", "Action"],

      pair_table_data: [],

      pair_table_dataUSDT: [],

      pair_table_dataBTC: [],

      pair_table_dataTRX: [],

      pair_table_dataETH: [],
      topGainerData: [],
      topLosserData: [],

      tickers: [],
      connection2: '',
      show: false,
      /****************************************** TABS DATA LOOP FINISH *******************************************/
    };
  },

  mounted() {
    this.getTopCoin();
  },

  methods: {
    selectTab(id) {
      this.active_tab = id;
      if (id == 'all') {
        this.market_table_data = ["Coin", "Last Price", "24H High", "24H Low", "24H Change", "Last 7 days", "Action"];
      }
      if (id == 'gainer') {
        this.market_table_data = ["Pair", "Last Price", "24H High", "24H Low", "24H Change", "Last 7 days", "Action"];
      }
      if (id == 'loser') {
        this.market_table_data = ["Pair", "Last Price", "24H High", "24H Low", "24H Change", "Last 7 days", "Action"];
      }
    },
    getSeriesData({ market_pair, market_price, market_change, market_subprice, color_rgb = 'rgb(60, 166, 129)', s_data = [] }) {
      return {
        market_pair,
        market_price,
        market_change,
        market_subprice,
        change_percantage_color: "var(--green)",
        seriesSpark3: [
          {
            data: s_data,
          },
        ],
        chartOptionsSpark3: {
          chart: {
            type: "area",
            height: 100,
            sparkline: {
              enabled: true,
            },
          },
          colors: [color_rgb],
          // stroke: {
          //   curve: "smooth",
          //   width: 1,
          //   lineCap: 'butt',
          // },
          // fill: {
          //   opacity: 0.3,
          // },
          // xaxis: {
          //   crosshairs: {
          //     width: 1,
          //   },
          // },
          // yaxis: {
          //   min: 0,
          // },
        },
      }
    },

    async getChartData() {
      var x = await ApiClass.getNodeRequest('dashboard/market-chart', false)
      x = x.data;
      this.$store.commit("SET_MARKET_CHART_DATA", x);
      this.setChartData(x)
    },

    async setChartData(x) {
      this.MarketBoxData[0].seriesSpark3[0].data = this.dataBTCUSDT = x.data.BTCUSDT;
      this.MarketBoxData[1].seriesSpark3[0].data = this.dataETHUSDT = x.data.ETHUSDT;
      this.MarketBoxData[2].seriesSpark3[0].data = this.dataTRXUSDT = x.data.TRXUSDT;
      this.MarketBoxData[3].seriesSpark3[0].data = this.dataXRPUSDT = x.data.XRPUSDT;
      // this.MarketBoxData[0].seriesSpark3[0].data = this.dataBTCUSDT = x.data.BTCUSDT.map((ele) => ele[4]);
      // this.MarketBoxData[1].seriesSpark3[0].data = this.dataETHUSDT = x.data.ETHUSDT.map((ele) => ele[4]);
      // this.MarketBoxData[2].seriesSpark3[0].data = this.dataTRXUSDT = x.data.TRXUSDT.map((ele) => ele[4]);
      // this.MarketBoxData[3].seriesSpark3[0].data = this.dataXRPUSDT = x.data.XRPUSDT.map((ele) => ele[4]);

      this.show = true;
    },
    async getListData() {

      let data2 = await ApiClass.getNodeRequest('list-crypto/get', false);
      data2 = data2.data;
      console.log("herre", data2);
      this.$store.commit("SET_LIST_DATA", data2);
      this.setListData(data2)
      this.pairChange("USDT")

    },
    setListData(data2) {
      this.webSocketSubs();
      // console.log('hihi',data2);
      this.pair_table_dataUSDT = data2.data.USDT;
      this.pair_table_dataBTC = data2.data.BTC;
      this.pair_table_dataTRX = data2.data.TRX;
      this.pair_table_dataETH = data2.data.ETH;
      this.tickers = data2.tickers;
      this.pairChange("USDT")
    },
    webSocketSubs() {
      const ticker_subs = this.tickers.map((v) => {
        return v.toLowerCase() + "@ticker";
      });
      //  console.log("Starting connection2 to WebSocket Server");
      this.connection2 = new WebSocket("wss://stream.binance.com:9443/ws");

      this.connection2.onopen = function () {
        // console.log("Successfully connected to the echo websocket server...");
      };

      var ccStreamer = this.connection2;

      this.connection2.onopen = function onStreamOpen() {
        // console.log("Stream Open");
        var subRequest2 = {
          method: "SUBSCRIBE",
          params: ticker_subs,
          id: 1,
        };
        ccStreamer.send(JSON.stringify(subRequest2));
      };

      this.connection2.onclose = function () {
        // console.log(
        //   "Successfully disconnected to the echo websocket server..."
        // );
      };
    },

    pairChange(pair) {

      var x = [];
      this.tab = pair;
      if (pair == "USDT") {
        x = this.pair_table_data = this.pair_table_dataUSDT;
      } else if (pair == "BTC") {
        x = this.pair_table_data = this.pair_table_dataBTC;
      } else if (pair == "TRX") {
        x = this.pair_table_data = this.pair_table_dataTRX;
        console.log('trx', x);
      } else if (pair == "ETH") {
        x = this.pair_table_data = this.pair_table_dataETH;
        console.log(x);
      }



      var $this = this;
      this.connection2.onmessage = function (event) {
        var e_data = event.data;
        e_data = JSON.parse(e_data);

        // for show live data on chart 
        if (e_data.s == "BTCUSDT") {

          $this.MarketBoxData[0] = $this.getSeriesData({
            market_pair: 'BTC-USDT',
            market_change: parseFloat(e_data.P).toFixed(2) >= 0 ? "+" + parseFloat(e_data.P).toFixed(2) : parseFloat(e_data.P).toFixed(2),
            market_price: parseFloat(e_data.c),
            market_subprice: parseFloat(e_data.h),
            color_rgb: parseFloat(e_data.P) >= 0 ? 'rgb(60, 166, 129)' : 'rgb(224, 34, 34)',
            s_data: $this.dataBTCUSDT
          });
          // $this.MarketBoxData[0].chartOptionsSpark3 = parseFloat(e_data.P) >= 0 ? { colors: ['rgb(60, 166, 129)'] } : { colors: ['rgb(224, 34, 34)'] }

        } else if (e_data.s == "ETHUSDT") {

          $this.MarketBoxData[1] = $this.getSeriesData({
            market_pair: 'ETH-USDT',
            market_change: parseFloat(e_data.P).toFixed(2) >= 0 ? "+" + parseFloat(e_data.P).toFixed(2) : parseFloat(e_data.P).toFixed(2),
            market_price: parseFloat(e_data.c),
            market_subprice: parseFloat(e_data.h),
            color_rgb: parseFloat(e_data.P) >= 0 ? 'rgb(60, 166, 129)' : 'rgb(224, 34, 34)',
            s_data: $this.dataETHUSDT
          });
        } else if (e_data.s == "TRXUSDT") {

          $this.MarketBoxData[2] = $this.getSeriesData({
            market_pair: 'TRX-USDT',
            market_change: parseFloat(e_data.P).toFixed(2) >= 0 ? "+" + parseFloat(e_data.P).toFixed(2) : parseFloat(e_data.P).toFixed(2),
            market_price: parseFloat(e_data.c),
            market_subprice: parseFloat(e_data.h),
            color_rgb: parseFloat(e_data.P) >= 0 ? 'rgb(60, 166, 129)' : 'rgb(224, 34, 34)',
            s_data: $this.dataTRXUSDT
          });
        } else if (e_data.s == "XRPUSDT") {

          $this.MarketBoxData[3] = $this.getSeriesData({
            market_pair: 'XRP-USDT',
            market_change: parseFloat(e_data.P).toFixed(2) >= 0 ? "+" + parseFloat(e_data.P).toFixed(2) : parseFloat(e_data.P).toFixed(2),
            market_price: parseFloat(e_data.c),
            market_subprice: parseFloat(e_data.h),
            color_rgb: parseFloat(e_data.P) >= 0 ? 'rgb(60, 166, 129)' : 'rgb(224, 34, 34)',
            s_data: $this.dataXRPUSDT
          });
        }

        x.find((o) => {
          if (o.symbol == e_data.s) {
            o.change = parseFloat(e_data.P).toFixed(2);
            (o.high = parseFloat(e_data.h)),
              (o.low = parseFloat(e_data.l)),
              (o.flag = o.price < e_data.c ? 1 : 2);

            o.price = parseFloat(e_data.c);
          }
        });
      };
    },
    async getTopCoin() {
      var response2 = await ApiClass.getNodeRequest('dashboard/market-gainers', false)
      var coinData = response2.data;

      this.$store.commit("SET_TOP_COIN_DATA", coinData.data);
      this.skeletonLoad = false;
      this.tableLoad = false;

      this.setTopCoin(coinData.data);
    },
    setTopCoin(coindata) {
      // console.log(coindata)
      this.coreAssetData = coindata.core_data
      this.topGainerData = coindata.gainers
      this.topLosserData = coindata.losers
      this.tickers = coindata.tickers
      this.webSocketSubs();
    },

  },

  async created() {
    var chartData = this.$store.getters.getMarketChart
    chartData ? this.setChartData(chartData) : '';
    var data2 = this.$store.getters.getListData
    data2 ? this.setListData(data2) : '';
    this.getChartData();
    this.getListData();

  },
  destroyed() {
    this.connection2.close();
  },
};
</script>

<style scoped>
.nav-pills .nav-link.active {
  background-color: var(--bg2);
  color: var(--onsurface) !important;
}

.nav-pills .nav-link {
  font-weight: 500;
}

.search-input span.input-group-text {
  border-radius: 10px 0 0 10px;
  background: var(--input-bg);
  padding-right: 0px;
  border-color: var(--input-border);
  color: var(--bs-gray);
}

.search-input input {
  border-radius: 0 10px 10px 0;
  padding: .375rem .5rem;
  border-left: 0 !important;
}
</style>